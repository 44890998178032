// Alto Parnaíba
const idPortalApi = "40c86c40b280ad723c77e753387d5e9ce9a15599";
const idForTransparenciaAdm = "6d83a282-79b4-4a9b-8460-49b14a94910b";

module.exports = {
  idPortalApi: `${idPortalApi}`,
  tokenLink: `${idPortalApi}`, //Token para acessar o WWW.transparência
  idForTransparenciaAdm: `${idForTransparenciaAdm}`,
  // Produção
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  // urlApi: `http://localhost:8004/${idPortalApi}`,
  urlADM: `https://administracaopublica.com.br`,

  dnsPortal: "viana.ma.gov.br",

  NomePortal: "Viana",
  ufPortal: "MA",
  NomeTipoPortal: "Prefeitura Municipal", //Prefeitura ou Câmara
  TipoPortal: 3, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true, //Essa opção informa se a rota de visualização de uma única noticia está disponível (Raposa gera as noticias pelo lado do servidor com nextjs)

  countNewsHome: 9, //quantidade de noticias na home

  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario

  link: "http://www.transparenciadministrativa.com.br", //Onde o token abaixo ser[a utilizado]
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem", //Onde o token abaixo ser[a utilizado]

  urlEmDeploy: "https://viana.ma.gov.br", //usada para link de compartilhamento
  imagemURL: "https://cdn.administracaopublica.com.br/complementares/", //link para a imagem
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  corIcones: "#000080",

  //links externos
  link_youtube: `#`,
  link_localize: `https://maps.app.goo.gl/P4ec6DSp62942wws6`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10473.850776324969!2d-44.99822663123538!3d-3.204146407356626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7f57d7a1756d717%3A0xd6f6dce1e3124def!2sViana%2C%20State%20of%20Maranh%C3%A3o%2C%2065215-000!5e0!3m2!1sen!2sbr!4v1737466896398!5m2!1sen!2sbr",
};
