import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import "../../../../styles/searchHome.css";

const SearchHome = () => {
  const [search, setSearch] = useState("");
  const [nameimg, setNameimg] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const img = [
      "/imagens/01.jpg",
    ];
    const numberMinute = parseInt(localStorage.getItem("numberMinute"));
    let idImg = parseInt(localStorage.getItem("IdImg"));

    if (isNaN(idImg) || idImg >= img.length || idImg < 0) {
      idImg = 0;
      localStorage.setItem("IdImg", idImg);
    }

    const minutesPassed = Math.floor((Date.now() - numberMinute) / 1000 / 60);

    if (isNaN(numberMinute) || minutesPassed >= 1) {
      const newImgId = (idImg + 1) % img.length;
      localStorage.setItem("numberMinute", Date.now());
      localStorage.setItem("IdImg", newImgId);
      setNameimg(img[newImgId]);
    } else {
      setNameimg(img[idImg]);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/pesquisar/${search}/`);
  };

  const BackgroundIMG = styled.div`
    background-image: url(${nameimg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: rgb(0, 0, 0);
    padding: 12%;
  `;

  return (
    <BackgroundIMG className="background-global">
      <div className="container-search-txt">
        <div className="container-search-txt1"></div>
      </div>

      <form className="container-search" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Buscar"
          name="search-main"
          className="input-search"
          onChange={(e) => setSearch(e.target.value)}
        />
        <button type="submit" className="link-search">
          <img
            src="/icons/search.svg"
            alt="logo buscar"
            className="svg-search"
          />
        </button>
      </form>
    </BackgroundIMG>
  );
};

export default SearchHome;
